import { usePage } from "@inertiajs/vue3";
import mitt from "mitt";
import { computed, ref } from "vue";
import Vue3Storage, { StorageType } from "vue3-storage";

import { getCookie } from "../utils";

export default {
    /*
     * `install` only runs once, and is passed the props of the page that is
     * first rendered at page load. These props are used as the initial value
     * for some values that are immutable (events url, etc).
     */
    install: (app, initialProps) => {
        const { event_url, feedback_mailto, start_chat_url, loading_image_src, staff_links } = initialProps;
        const eventContext = ref({});

        const sendEvent = async function (eventType, data) {
            const response = await fetch(event_url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                body: JSON.stringify({
                    event_type: eventType,
                    ...data,
                }),
            });

            const body = await response.json();
            body.status = response.status;

            if (!response.ok) {
                return Promise.reject(body);
            }

            return body;
        };

        const emitter = mitt();

        // Set up storages
        app.use(Vue3Storage, { namespace: "nadia_", storage: StorageType.Local });

        // Set up emitters
        app.config.globalProperties.emitter = emitter;

        // Set up global constants with server side variables.
        app.config.globalProperties.$loadingImageSrc = loading_image_src;
        app.config.globalProperties.$startChatUrl = start_chat_url;
        app.config.globalProperties.$staffLinks = staff_links;
        app.config.globalProperties.$feedbackMailto = feedback_mailto;

        // Helper methods for sending events.
        // Attach any properties that will be sent in every $sendEvent call for a page
        app.config.globalProperties.$setEventContext = (context) => {
            eventContext.value = context;
        };
        app.config.globalProperties.$sendEvent = async (event_type, data) => {
            return sendEvent(event_type, { ...eventContext.value, ...data });
        };

        const page = usePage();
        app.provide(
            "user_details",
            computed(() => page.props.user_details),
        );
        app.provide(
            "organization_details",
            computed(() => page.props.organization_details),
        );

        // Make $sendEvent available to composition API components, as in: `const sendEvent = inject("sendEvent")`
        app.provide("sendEvent", app.config.globalProperties.$sendEvent);
        app.provide("globalProperties", app.config.globalProperties);
    },
};
